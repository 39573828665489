/**
 * @generated SignedSource<<877c95ad756717d7a68381cc69b5e9e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameItemHero_data$data = {
  readonly game: {
    readonly " $fragmentSpreads": FragmentRefs<"HeroGame_data">;
  };
  readonly gameTags: ReadonlyArray<string>;
  readonly playButtonText: string | null;
  readonly " $fragmentType": "GameItemHero_data";
};
export type GameItemHero_data$key = {
  readonly " $data"?: GameItemHero_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameItemHero_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameItemHero_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gameTags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playButtonText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroGame",
      "kind": "LinkedField",
      "name": "game",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroGame_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GameItemHero",
  "abstractKey": null
};

(node as any).hash = "0ccf48ba172d71a8e04968409629d282";

export default node;
